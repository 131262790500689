import React from "react"
import styled, { keyframes } from "styled-components"
import { bounceInLeft, bounceInRight } from "react-animations"

import variables from "../constants/variables"

const Banner = ({ title, subtitle, info, image, children, error, home }) => {
  return (
    <BannerStyle className="mx-auto" error={error} home={home}>
      <div className="imageWrapper">{image}</div>
      <h1 className="d-none d-xl-block">{title}</h1>
      <h2 className="d-none d-xl-block">{subtitle}</h2>
      <p className="d-none d-xl-block mb-5">{info}</p>
      {children}
    </BannerStyle>
  )
}

const bounceInLeftAnimation = keyframes`${bounceInLeft}`
const bounceInRightAnimation = keyframes`${bounceInRight}`

const BannerStyle = styled.div`
  text-align: center;
  letter-spacing: ${variables.SPACING.mainSpacing};
  color: ${variables.COLORS.mainWhite};
  /* IE fix */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /* IE fix end */
  h1 {
    font-size: 3.3rem !important;
    color: ${props => (props.error ? "red" : "white")};
    text-transform: uppercase;
    margin-bottom: 2rem;
    padding: 0 1rem;
    letter-spacing: 6px;
    animation: 3s ${bounceInLeftAnimation};
  }
  h2 {
    animation: 3s ${bounceInRightAnimation};
  }
  p {
    width: 85%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .imageWrapper {
    margin-bottom: ${props => (props.home ? "3rem" : 0)};
  }
  .gatsby-image-wrapper {
    width: 200px;
    margin: 0 auto;
    img {
      border-radius: 500px;
    }
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 4.5rem;
    }
    p {
      width: 70%;
    }
    .gatsby-image-wrapper {
      width: 300px;
    }
  }
`

export default Banner
